import React, { FC, useState } from "react";

import { StarFilled, CloseOutlined } from "@ant-design/icons";

import { CkModal } from "../../../../CkUI";
import { dateType } from "../index";

const rateFilters = [
  {
    label: "5 estrellas",
    loopNumber: 5,
    rankingTo: 5,
    rankingFrom: 4.5,
  },
  {
    label: "4 estrellas",
    loopNumber: 4,
    rankingTo: 4.4,
    rankingFrom: 3.5,
  },
  {
    label: "3 estrellas",
    loopNumber: 3,
    rankingTo: 3.4,
    rankingFrom: 2.5,
  },
  {
    label: "2 estrellas",
    loopNumber: 2,
    rankingTo: 2.4,
    rankingFrom: 1.5,
  },
  {
    label: "1 estrellas",
    loopNumber: 1,
    rankingTo: 1.4,
    rankingFrom: 0,
  },
];

const dateFilters: { label: string; value: dateType }[] = [
  {
    label: "Último mes",
    value: "lastMonth",
  },
  {
    label: "Últimos 3 meses",
    value: "last3Months",
  },
  {
    label: "Últimos 6 meses",
    value: "last6Months",
  },
  {
    label: "Último año",
    value: "lastYear",
  },
];

const FilterModal: FC<{
  open: boolean;

  initialDate: dateType;
  initialRate:
    | {
        rankingFrom: number;
        rankingTo: number;
      }
    | undefined;

  closeModal: Function;
  action: Function;
  reset: Function;
}> = ({ open, initialDate, initialRate, closeModal, action, reset }) => {
  // Filters
  const [dateRange, setDateRange] = useState<dateType | undefined>(initialDate);
  const [rateRange, setRateRange] = useState<
    | {
        rankingFrom: number;
        rankingTo: number;
      }
    | undefined
  >(initialRate);

  return (
    <CkModal
      open={open}
      zIndex={3000}
      title="Añadir filtros"
      onCancel={() => closeModal()}
      primaryAction={{
        label: "Aplicar filtros",
        onClick: () => action(dateRange, rateRange),
      }}
      secondaryAction={{
        label: "Borrar filtros",
        onClick: () => reset(),
      }}
    >
      <div className="filters-container">
        <p className="title">Estrellas</p>
        {rateFilters.map((rate) => (
          <div
            className={`option ${
              rateRange && rateRange.rankingTo == rate.rankingTo ? "active" : ""
            }`}
            onClick={() => {
              if (
                rateRange == undefined ||
                rateRange.rankingTo !== rate.rankingTo
              ) {
                setRateRange({
                  rankingFrom: rate.rankingFrom,
                  rankingTo: rate.rankingTo,
                });
              }
            }}
          >
            <div className="option-row">
              <p className="info">{rate.label}</p>
              {Array.from({ length: rate.loopNumber }).map((item) => (
                <StarFilled />
              ))}
            </div>
            {rateRange && rateRange.rankingTo == rate.rankingTo && (
              <CloseOutlined onClick={() => setRateRange(undefined)} />
            )}
          </div>
        ))}
        <p className="title">Meses</p>
        {dateFilters.map((date) => (
          <div
            className={`option ${
              dateRange && dateRange == date.value ? "active" : ""
            }`}
            onClick={() => {
              if (dateRange == undefined || dateRange !== date.value) {
                setDateRange(date.value);
              }
            }}
          >
            <div className="option-row">
              <p className="info">{date.label}</p>
            </div>
            {dateRange && dateRange == date.value && (
              <CloseOutlined onClick={() => setDateRange(undefined)} />
            )}
          </div>
        ))}
      </div>
    </CkModal>
  );
};

export default FilterModal;
