import React, { useState, useEffect } from "react";
import moment from "moment";
import { FeedbackAPI } from "../../app/Services/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkshops,
  getLoading,
  getWorkshopSelected,
  getSasToken,
  setCurrentSection,
} from "../../stores";
import {} from "../../stores";

import {
  Row,
  Col,
  Avatar,
  Rate,
  Drawer,
  message,
  Spin,
  Button,
  Divider,
  Table,
  Input,
} from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Workshop } from "shared/src/data-models/Workshop";
import avatarDefault from "../../assets/Images/avatarDefault.svg";
import RefreshIco from "../../assets/Images/refresh_02.svg";
import { useWindowSize } from "../../app/Utilities/WindowSize";
import { CkMessage } from "../../CkUI";
const Ratings = () => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const [selectedWorkshop, setSelectedWorkshop] = useState<any>();
  const [selectedWorkshopRatings, setSelectedWorkshopRatings] = useState<any[]>(
    []
  );
  const [selectedWorkshopRatingDetails, setSelectedWorkshopRatingDetails] =
    useState<any[]>([]);
  const [seeMore, setSeeMore] = useState<string[]>([]);
  const [score, setScore] = useState<number>(0);
  const [feedbackNum, setFeedbackNum] = useState<number>(0);
  const [logo, setLogo] = useState<string>("");
  const [selectedConversation, setSelectedConversation] = useState<any>();
  const [text, setText] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(true);
  const sasToken: string = useSelector(getSasToken);
  const workshops: Workshop[] = useSelector(getWorkshops);
  const workshopSelected: number = useSelector(getWorkshopSelected);

  useEffect(() => {
    dispatch(setCurrentSection(""));
  }, []);

  useEffect(() => {
    getAllFeedbacks();
  }, [workshops, workshopSelected]);

  const handleSeeMore = (id: string) => {
    const temp = [...seeMore];
    if (temp.includes(id)) {
      const index = temp.findIndex((a) => a === id);
      temp.splice(index, 1);
    } else {
      temp.push(id);
    }
    setSeeMore(temp);
  };

  const textWithNewLines = (text: string) => {
    const split = text.split("\n");
    return split.map((line) => (
      <p
        style={{
          margin: 0,
        }}
      >
        {line}
      </p>
    ));
  };

  const sendMessage = () => {
    FeedbackAPI.addMessage(
      selectedConversation.messages[selectedConversation.messages.length - 1]
        .id,
      text
    )
      .then(() => {
        const id = selectedConversation.workshopAppointmentId;
        FeedbackAPI.getWorkshopRating(
          workshops[workshopSelected]?.id!,
          page,
          pageSize
        ).then((res) => {
          setSelectedWorkshopRatings(res.data.ratings);
          setSelectedWorkshopRatingDetails(res.data.ratingDetails);
          setScore(res.data.feedbackScore);
          setFeedbackNum(res.data.numberOfFeedbacks);
          const temp = res.data.ratingDetails.find(
            (a) => a.workshopAppointmentId === id
          );
          if (temp) {
            setSelectedConversation(temp);
          }
        });
        CkMessage({type: "success", text: "Respuesta enviada correctamente"});
        setText("");
      })
      .catch((err) => {
        CkMessage({type: "error", text: "Ocurrió un error al enviar la respuesta"});
      });
  };

  const handlePage = (page: number, pageSize: number) => {
    if (page) {
      setLoading(true);
      setPage(page);
      setPageSize(pageSize);
      const sliced = selectedWorkshopRatingDetails.slice(
        (page - 1) * pageSize,
        page * pageSize
      );
      if (!sliced.includes(undefined)) {
        setLoading(false);
        return;
      }
      FeedbackAPI.getWorkshopRating(selectedWorkshop.id, page, pageSize)
        .then((res) => {
          const ratingDetails = res.data.ratingDetails;
          let savedArray = [...selectedWorkshopRatingDetails];
          const emptyArray = new Array(res.data.pages * pageSize);
          const extra =
            emptyArray.length -
            Math.ceil(savedArray.length / pageSize) * pageSize;
          savedArray = [...savedArray, ...new Array(extra)];

          savedArray.splice((page - 1) * pageSize, pageSize, ...ratingDetails);

          setSelectedWorkshopRatingDetails(savedArray);
        })
        .catch((error) => console.info("error", error))
        .finally(() => setLoading(false));
    }
  };

  const reload = () => {
    const sliced = selectedWorkshopRatingDetails.slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    if (!sliced.includes(undefined)) {
      setLoading(false);
      return;
    }
    FeedbackAPI.getWorkshopRating(selectedWorkshop.id, page, pageSize)
      .then((res) => {
        const ratingDetails = res.data.ratingDetails;
        let savedArray = [...selectedWorkshopRatingDetails];
        const emptyArray = new Array(res.data.pages * pageSize);
        const extra =
          emptyArray.length -
          Math.ceil(savedArray.length / pageSize) * pageSize;
        savedArray = [...savedArray, ...new Array(extra)];

        savedArray.splice((page - 1) * pageSize, pageSize, ...ratingDetails);

        setSelectedWorkshopRatingDetails(savedArray);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getAllFeedbacks = () => {
    if (workshops.length > 0) {
      FeedbackAPI.getWorkshopRating(
        workshops[workshopSelected]?.id!,
        1,
        pageSize
      ).then((res) => {
        setLoading(false);
        const tempArray = new Array(res.data.pages * pageSize);
        tempArray.splice(0, pageSize, ...res.data.ratingDetails);

        setSelectedWorkshop(workshops[workshopSelected]);
        const image = workshops[workshopSelected].workshopPhotos.find(
          (a) => a.description === "LOGO"
        )?.photoUrl;
        setLogo(`${image}?${sasToken}`);
        setSelectedWorkshopRatings(res.data.ratings);
        setSelectedWorkshopRatingDetails(tempArray);
        setScore(res.data.feedbackScore);
        setFeedbackNum(res.data.numberOfFeedbacks);
        setPage(1);
      });
    }
  };

  useEffect(() => {
    if (page && selectedWorkshop) {
      reload();
    }
  }, [page]);

  return (
    <div style={{ margin: "15px max(2%, 15px)" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3
          className="blue"
          style={{
            margin: 0,
            display: "inline-block",
          }}
        >
          Mis calificaciones
        </h3>
        <Button
          style={{
            background: "transparent",
            color: "var(--carker-blue)",
            border: "none",
            alignSelf: "center",
            borderRadius: 10,
            paddingLeft: "10px",
            verticalAlign: "text-bottom",
            display: "inline-flex",
          }}
          icon={
            <img
              className="img-refreshIco img-icon-double"
              src={RefreshIco}
              alt="Refrescar"
            />
          }
          onClick={() => getAllFeedbacks()}
        >
          <p
            style={{
              paddingLeft: "3px",
              alignSelf: "baseline",
              paddingTop: "2px",
            }}
          >
            Refrescar
          </p>
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24} style={{ padding: "0 max(2%, 15px)" }}>
          <div
            className="div-row"
            style={{
              padding: "0 0 15px",
              justifyContent: "space-between",
            }}
          >
            <Avatar
              src={logo ? logo : avatarDefault}
              id="avatar"
              style={{
                width: "77px",
                height: "77px",
                background: "white",
              }}
            />
            <div>
              <h6
                className="black"
                style={{
                  margin: 0,
                }}
              >
                {selectedWorkshop?.name}
                {selectedWorkshop?.sucursalName
                  ? " - " + selectedWorkshop?.sucursalName
                  : ""}
              </h6>
              <div className="div-row" style={{ marginTop: "-10px" }}>
                <Rate disabled value={score} />
                <p style={{ margin: "0px 5px 0px 0.8rem" }}>
                  <h3
                    className="blue"
                    style={{
                      display: "inline-block",
                      margin: "5px 0px",
                    }}
                  >
                    {score}
                  </h3>{" "}
                  de 5
                </p>
              </div>
              <p
                style={{
                  fontFamily: "Emprint-semibold",
                  margin: 0,
                  color: "#323232",
                }}
                className="mobile-size"
              >
                {feedbackNum}
                {` calificaci${feedbackNum !== 1 ? "ones" : "ón"}`}
              </p>
            </div>
          </div>
          <Row gutter={[16, 8]}>
            {selectedWorkshopRatings?.map((rating: any, index: number) => (
              <>
                <Col
                  span={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p style={{ margin: 0, fontFamily: "emprint-semibold" }}>
                    {rating.feedbackCategoryName}
                  </p>
                </Col>
                <Col
                  span={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="div-row" style={{ marginLeft: "auto" }}>
                    <Rate disabled value={rating.rating} />
                    <p style={{ margin: "0 0.4rem" }}>{rating.rating}</p>
                  </div>
                </Col>
              </>
            ))}
          </Row>
        </Col>
        <Col md={12} xs={24}>
          <>
            <h6
              className="blue"
              style={{
                marginTop: "0.8rem",
              }}
            >
              Comentarios de los clientes
            </h6>
            <Divider
              style={{
                borderTop: "1px solid #2f66f2",
                margin: 0,
                width: "85%",
                minWidth: "85%",
              }}
            />
            <div id="rating-table">
              <Table
                loading={loading}
                dataSource={selectedWorkshopRatingDetails}
                showHeader={false}
                pagination={{
                  current: page,
                  defaultPageSize: 5,
                  pageSizeOptions: ["5", "10", "15"],
                  showSizeChanger: true,
                  onChange: (page, pageSize) => handlePage(page, pageSize),
                }}
              >
                <Table.Column
                  dataIndex="messages"
                  render={(messages: any[]) => {
                    return messages?.length > 0 ? (
                      messages[0].isVisible ? (
                        <CheckCircleFilled className={`rating-icon visible`} />
                      ) : null /*messages[0].isVisible === null ? (
                        <CheckCircleFilled
                          className={`rating-icon null-visible`}
                        />
                      ) : (
                        <CloseCircleFilled
                          className={`rating-icon not-visible`}
                        />
                      )*/
                    ) : (
                      <CheckCircleFilled className={`rating-icon visible`} />
                    );
                  }}
                />
                <Table.Column
                  render={(val, detail: any) => (
                    <div
                      key={detail?.workshopAppointmentId}
                      style={{ padding: 10 }}
                      className="div-row"
                    >
                      <Avatar
                        src={
                          detail?.endConsumer.photoUrl && sasToken
                            ? `${detail.endConsumer.photoUrl}?${sasToken}`
                            : avatarDefault
                        }
                        id="avatar"
                        style={{
                          width: 50,
                          minWidth: 50,
                          maxWidth: 50,
                          height: 50,
                          minHeight: 50,
                          maxHeight: 50,
                          border: "solid 1px #2f66f2",
                        }}
                      />
                      <div style={{ marginLeft: "0.4rem", width: "100%" }}>
                        <div
                          className="div-row"
                          style={{
                            justifyContent:
                              detail?.messages.length > 0
                                ? "space-between"
                                : "unset",
                          }}
                        >
                          <h6
                            className="black"
                            style={{
                              margin: 0,
                              color: "#323232",
                            }}
                          >
                            {detail?.endConsumer.name}{" "}
                            {detail?.endConsumer.lastName}
                          </h6>
                          {detail?.messages.filter(
                            (m) =>
                              m.isVisible === true &&
                              m.isWorkshopMessage === false
                          ).length > 0 && (
                            <Button
                              style={{
                                cursor: "pointer",
                                border: "none",
                                background: "none",
                                boxShadow: "none",
                              }}
                              icon={<CaretDownOutlined />}
                              onClick={() =>
                                handleSeeMore(detail.workshopAppointmentId)
                              }
                            >
                              {`Ver ${
                                seeMore.includes(detail.workshopAppointmentId)
                                  ? "menos"
                                  : "más"
                              }`}
                            </Button>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: -5,
                            marginBottom: 2,
                          }}
                          className="div-row"
                        >
                          <Rate
                            disabled
                            defaultValue={detail?.endConsumerFeedbackScore}
                            style={{ fontSize: "12px" }}
                          />
                          <p style={{ margin: "0 0.8rem" }}>
                            {detail?.endConsumerFeedbackScore}
                          </p>
                        </div>
                        {seeMore.includes(detail?.workshopAppointmentId) && (
                          <div
                            className="div-row"
                            style={{ justifyContent: "space-between" }}
                          >
                            <p
                              style={{
                                margin: 0,
                              }}
                            >
                              {detail?.messages.length > 0
                                ? detail.messages[0].message
                                : ""}
                            </p>

                            <Button
                              size="large"
                              className="button_blue"
                              onClick={() => setSelectedConversation(detail)}
                            >
                              {detail?.messages.length > 0 &&
                              detail?.messages.filter(
                                (x) => x.isWorkshopMessage === true
                              ).length > 0
                                ? "Ver conversación"
                                : "Responder"}
                            </Button>
                          </div>
                        )}
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {moment(detail?.createdDate).format("DD/MM/YY")}
                        </p>
                      </div>
                    </div>
                  )}
                />
              </Table>
            </div>
          </>
        </Col>
      </Row>
      <Drawer
        closable
        destroyOnClose
        title={null}
        footer={null}
        open={selectedConversation}
        onClose={() => setSelectedConversation(undefined)}
        width={
          windowSize.width > 768
            ? "40%"
            : windowSize.width >= 576
            ? "60%"
            : "100%"
        }
        style={{ zIndex: 2147483641 }}
        bodyStyle={{ padding: "15px max(2%, 15px)" }}
      >
        <h6
          className="blue"
          style={{
            marginTop: "0.8rem",
          }}
        >
          Comentarios de los clientes
        </h6>
        <Divider
          style={{
            borderTop: "1px solid #2f66f2",
            margin: 0,
            width: "100%",
            minWidth: "100%",
          }}
        />
        {selectedConversation?.messages.length > 0 ? (
          selectedConversation?.messages.map((message: any, index: number) => (
            <Row
              gutter={[16, 16]}
              key={`con${index}`}
              style={{ marginTop: 15, justifyContent: "space-between" }}
            >
              <Col
                span={2}
                offset={message.isWorkshopMessage ? 1 : 0}
                style={{ textAlign: "center" }}
              >
                <Avatar
                  id="avatar"
                  src={
                    message.isWorkshopMessage
                      ? logo
                      : selectedConversation.endConsumer.photoUrl
                      ? `${selectedConversation.endConsumer.photoUrl}?${sasToken}`
                      : avatarDefault
                  }
                  style={{
                    background: "white",
                    border: "solid 0.5px rgb(47, 102, 242)",
                  }}
                />
              </Col>
              <Col span={message.isWorkshopMessage ? 20 : 21}>
                <div style={{ display: "flex" }}>
                  <h6
                    className="black"
                    style={{
                      marginRight: "0.8rem",
                      marginBottom: 0,
                    }}
                  >
                    {message.senderName}
                  </h6>
                  <p style={{ color: "rgba(0, 0, 0, 0.4)", margin: 0 }}>
                    {moment.utc(message.createdDate).local().fromNow()}
                  </p>
                </div>
                {message.message ? textWithNewLines(message.message) : null}
                {message.isWorkshopMessage && message.isVisible == null ? (
                  <p>
                    Su mensaje está siendo revisado por Servicio al cliente
                    antes de publicarse
                  </p>
                ) : null}
              </Col>
            </Row>
          ))
        ) : (
          <p>Esta conversación no contiene ningún mensaje</p>
        )}
        {selectedConversation?.messages.length > 0 &&
        selectedConversation?.messages.filter(
          (x) => x.isWorkshopMessage === true
        ).length > 0 ? null : (
          <>
            <Input.TextArea
              value={text}
              onChange={(e) => setText(e.target.value)}
              style={{ marginTop: "0.8rem" }}
            />
            <Button
              size="large"
              className="button_blue"
              onClick={sendMessage}
              style={{
                marginTop: "0.8rem",
                //width: "100%",
              }}
            >
              Enviar
            </Button>
          </>
        )}
      </Drawer>
    </div>
  );
};

export default Ratings;
