import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useWindowSize } from "../../app/Utilities/WindowSize";
import { PromoCodesAPI } from "../../app/Services/axios";
import { CurrentUser } from "../../context/UserContext";
import MyProfile from "./MyProfile/index";
import { Helmet } from "react-helmet";
import { CkMessage } from "../../CkUI";

export const ProfileMenu: React.FC = () => {
  const match: any = useRouteMatch("/configuracion/:menu");
  const user = React.useContext(CurrentUser);
  const history = useHistory();
  const windowSize = useWindowSize();
  const [selectedKey, setSelectedKey] = useState<string>(match?.params.menu);
  const [previousKey, setPreviousKey] = useState<string>("data");

  const [showPromoModal, setShowPromoModal] = React.useState<boolean>(false);
  const [showPromoSended, setShowPromoSended] = React.useState<boolean>(false);
  const [invitedEmail, setInvitedEmail] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const ChangeMenu = (key: string) => {
    history.push(key);
    setPreviousKey(selectedKey);
    setSelectedKey(key);
  };

  const HandleSendInvitation = ({ email }: any) => {
    setLoading(true);
    setInvitedEmail(email);
    PromoCodesAPI.getInvitationCode({
      userReferrerId: parseInt(user?.id!),
      userInvitedEmail: email,
    })
      .then((result) => {
        if (result) {
          if (result.data !== "") {
            PromoCodesAPI.inviteUser({
              senderUserId: parseInt(user?.id!),
              email,
            })
              .then((result) => {
                if (result.status === 200) {
                  setShowPromoSended(true);
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          CkMessage({
            type: "error",
            text: "Ya existe una invitación a ese correo electrónico.",
          });
        } else {
          CkMessage({
            type: "error",
            text: "Hubo un problema al enviar la invitación, intenta nuevamente",
          });
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Helmet>
        <title>CarKer Para Talleres Automotrices</title>‍
        <meta
          name="description"
          content="Digitaliza tu taller automotriz formando parte de la plataforma CarKer y conecta con más clientes."
          data-react-helmet="true"
        />
      </Helmet>
      <MyProfile />
    </div>
  );
};
