import React, { memo, useEffect, useState, useRef } from "react";
import {
  Input,
  Form,
  Button,
  Divider,
  Upload,
  Image,
  Radio,
  Checkbox,
} from "antd";
import TextArea from "antd/lib/input/TextArea";

import avatarDefault from "../../../assets/Images/avatarDefault.svg";
import {
  DeleteProfileIcon,
  WhatsappIcon,
} from "../../../assets/SvgIcons";
import { WarningOutlined, PhoneOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

import {
  updateUser,
  updatePassword,
  fullDeleteUser,
  clearWorkshopStorage,
} from "shared";
import {
  getSasToken,
  getUser,
  getUserAction,
  setCurrentSection,
} from "../../../stores";
import { BlobServiceClient } from "@azure/storage-blob";
import { encryptHMAC } from "../../../app/Utilities/Encryption";
import { checkIfPersonIsAdult } from "../../../app/Utilities/Dates";
import Compressor from "compressorjs";

import { ConfirmationModalData } from "../../../app/models/modal";
import { ConfirmationModal } from "../../../app/Components/Modals/ConfirmationModal";
import { AuthRest } from "../../../auth/SignInAuth";

import "./style.css";
import moment from "moment";
import {
  CkButton,
  CkDatePicker,
  CkInput,
  CkModal,
  CkEmptyImage,
  CkMessage,
  CkIcon,
} from "../../../CkUI";
import DataDeletionNotice from "../../../app/Components/DataDeletionNotice";
import { useWindowSize } from "../../../app/Utilities/WindowSize";

const authRest = new AuthRest();
const account = process.env.REACT_APP_STORAGEACCOUNT as string;
const containerName = "up-images-container";
let blobService: any = null;
let containerClient: any = null;

const MyProfile: React.FC = () => {
  const messageKey = useRef<string>();
  const [profileForm] = Form.useForm();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const userAction = useSelector(getUserAction);
  const sasToken: string = useSelector(getSasToken);
  const windowSize = useWindowSize();

  const [passwordForm] = Form.useForm();

  const [passwordFlag, setPasswordFlag] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = React.useState<string>("");

  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] =
    useState<boolean>(false);
  const [showDeleteAccountMessage, setShowDeleteAccountMessage] =
    useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModalData>({ visible: false, index: -1 });
  const [deleteText, setDeleteText] = useState<string>("");
  const [enableMessage, setEnableMessage] = React.useState<boolean>(false);
  const [deleteReasonMessage, setDeleteReasonMessage] = useState<string>();
  const [deleteReason, setDeleteReason] = useState<string>();

  const [isOwner, setIsOwner] = useState<boolean>(true);
  const [ownerModal, setOwnerModal] = useState<boolean>(false);
  const [isDataRetentionModalOpen, setIsDataRetentionModalOpen] = useState(false);

  const passwordRegex = new RegExp(
    /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-\#\$\.\%\&\*\!\@\/\\\(\)\_\+\=\;\:\"\'\|\?\,\<\>\[\]\{\}])(?=.*[a-zA-Z]).{8,}$/
  );

  const reasons = [
    "La plataforma no me parece útil o su uso es demasiado vago.",
    "Tengo otra plataforma que cubre las funciones de Carker.",
    "He tenido malas experiencias con los consumidores que reservaron algun servicios.",
    "Es complicado crear reservaciones o citas de mis clientes",
  ];

  useEffect(() => {
    if (user) {
      if (user.userRoles && user.userRoles.length > 0) {
        let ownerRoles: any[] = user.userRoles.filter(
          (item) => item.roleCode == "OWN"
        );
        setIsOwner(ownerRoles.length > 0);
      } else {
        setIsOwner(false);
      }

      setPhotoUrl(user.photoUrl);
    }
  }, [user]);

  useEffect(() => {
    if (userAction[0] === "deleted" && userAction[1] === true) {
      logout();
    }
  }, [userAction]);

  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      containerClient = blobService.getContainerClient(containerName);
    }
  }, [sasToken]);

  useEffect(() => {
    dispatch(setCurrentSection("Mi perfil"));
  }, []);

  useEffect(() => {
    if (enableMessage === false) handleReasonMessage("");
  }, [enableMessage]);

  const infoFailed = (data: any) => {
    console.info("onFinishFailed");
    if (data) {
      let failedMessage: string = "";
      let failedList: any[] = data.errorFields;

      if (failedList) {
        if (failedList.length == 1) {
          failedMessage = "Completa correctamente el siguiente campo:";
        } else {
          failedMessage = "Completa correctamente los siguientes campos:";
        }

        failedList.map((error) => {
          switch (error.name[0]) {
            case "firstName":
              failedMessage += "\nNombre(s),";
              break;
            case "lastName":
              failedMessage += "\nApellido(s),";
              break;
            case "phone":
              failedMessage += "\nTeléfono celular,";
              break;
            case "date":
              failedMessage += "\nFecha de nacimiento,";
              break;
            case "gender":
              failedMessage += "\nGénero,";
              break;
            case "email":
              failedMessage += "\nCorreo electrónico,";
              break;
            default:
              break;
          }
        });

        
        messageKey.current = CkMessage({
          type: "error",
          text: failedMessage.substr(0, failedMessage.length - 1),
          ...(messageKey.current
            ? {
                messageToDestroy: messageKey.current,
              }
            : {}),
        });
      }
    } else {
      
      messageKey.current = CkMessage({
        type: "error",
        text: "Asegúrate de completar los campos requeridos.",
        ...(messageKey.current
          ? {
              messageToDestroy: messageKey.current,
            }
          : {}),
      });
    }
  };

  const passwordFailed = (data: any) => {
    if (data) {
      let failedMessage: string = "";
      let failedList: any[] = data.errorFields;

      if (failedList) {
        if (failedList.length == 1) {
          failedMessage = "Completa correctamente el siguiente campo:";
        } else {
          failedMessage = "Completa correctamente los siguientes campos:";
        }

        failedList.map((error) => {
          switch (error.name[0]) {
            case "newPassword":
              failedMessage += "\nNueva contraseña,";
              break;
            case "repNewPassword":
              failedMessage += "\nConfirmar nueva contraseña,";
              break;
            default:
              break;
          }
        });
        
        messageKey.current = CkMessage({
          type: "error",
          text: failedMessage.substr(0, failedMessage.length - 1),
          ...(messageKey.current
            ? {
                messageToDestroy: messageKey.current,
              }
            : {}),
        });
      }
    } else {
      
      messageKey.current = CkMessage({
        type: "warning",
        text: "Asegúrate de completar los campos requeridos.",
        ...(messageKey.current
          ? {
              messageToDestroy: messageKey.current,
            }
          : {}),
      });
    }
  };

  const props = {
    name: "file",
    multiple: false,
    customRequest: ({ onSuccess, onError, file }: any) => {
      uploadImage(onSuccess, onError, file);
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        
        messageKey.current = CkMessage({
          type: "success",
          text: `${info.file.name} subida exitosamente.`,
          ...(messageKey.current
            ? {
                messageToDestroy: messageKey.current,
              }
            : {}),
        });
      } else if (status === "error") {
        
        messageKey.current = CkMessage({
          type: "error",
          text: `${info.file.name} error al subir imagen.`,
          ...(messageKey.current
            ? {
                messageToDestroy: messageKey.current,
              }
            : {}),
        });
      }
    },
  };

  const uploadImage = async (
    onSuccess: Function,
    onError: Function,
    file: any
  ) => {
    let date = new Date();
    let fileName = `${date.getTime()}_${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    try {
      new Compressor(file, {
        quality: 0.6,
        convertTypes:
          "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
        convertSize: 2000000,
        async success(result) {
          await blockBlobClient.upload(result, result.size);
          setPhotoUrl(blockBlobClient.url.split("?")[0]);
          await onFinish(
            {
              firstName: user ? user.name : "",
              lastName: user ? user.lastName : "",
              phone: user ? user.phone : undefined,
              //@ts-ignore
              gender: user ? user.sex : undefined,
              email: user ? user.email : "",
            },
            blockBlobClient.url.split("?")[0]
          );
          onSuccess();
        },
      });
    } catch (error) {
      onError();
    }
  };

  const onFinish = async (data: any, newPhotoUrl: string | null = null) => {
    console.info("onFinish");
    if (data?.date && !checkIfPersonIsAdult(data.date)) {
      profileForm.setFields([
        {
          name: "date",
          errors: ["Debe ser mayor de edad"],
        },
      ]);
      return;
    }
    
    messageKey.current = CkMessage({
      type: "loading",
      text: "Actualizando datos",
      ...(messageKey.current
        ? {
            messageToDestroy: messageKey.current,
          }
        : {}),
    });
    dispatch(
      updateUser({
        ...user!,
        name: data.firstName,
        lastName: data.lastName,
        sex: data.gender,
        phone: data.phone,
        email: data.email,
        photoUrl: newPhotoUrl ? newPhotoUrl : photoUrl,
        dateOfBirth: data?.date ? moment(data.date, "YYYY-MM-DD") : null,
      })
    )
      .then(() => {
        
        messageKey.current = CkMessage({
          type: "success",
          text: "Se actualizó el perfil exitosamente",
          ...(messageKey.current
            ? {
                messageToDestroy: messageKey.current,
              }
            : {}),
        });
      })
      .catch(() => {
        
        messageKey.current = CkMessage({
          type: "error",
          text: `Se produjo un error al actualizar el perfil`,
          ...(messageKey.current
            ? {
                messageToDestroy: messageKey.current,
              }
            : {}),
        });
      });
  };

  const onPasswordChange = async (data: any) => {
    const encryptedPassword = encryptHMAC(data.newPassword);
    Promise.resolve(dispatch(updatePassword(encryptedPassword))).then(
      (res: any) => {
        setPasswordFlag(false);
        passwordForm.resetFields(["newPassword", "repNewPassword"]);
        messageKey.current = CkMessage({
          type: "success",
          text: "Contraseña actualizada exitosamente",
          ...(messageKey.current
            ? {
                messageToDestroy: messageKey.current,
              }
            : {}),
        });
      }
    );
  };

  const logout = () => {
    clearWorkshopStorage();
    authRest.logout();
    setTimeout(() => window.location.replace("/"), 1000);
  };

  const handleReasonCheckbox = (value: string, checked: any) => {
    let newStr = deleteReason;
    if (checked) {
      if (newStr) {
        newStr = `${newStr} ${value}`;
      } else {
        newStr = value;
      }
    } else {
      if (newStr) {
        newStr = newStr.replace(value, "");
      }
    }
    setDeleteReason(newStr);
  };
  const handleReasonMessage = (value: string) => {
    setDeleteReasonMessage(value);
  };

  const RemoveCoworker = (index: number) => {
    // setSelectedMenuKey("0");
    // setCurrentCoworkerKey(0);
    // setConfirmationModal({ visible: false, index: -1 });
    let finalReason = deleteReason + " " + deleteReasonMessage;
    let obj = {
      id: user?.id,
      reason: finalReason,
    };
    if (user?.id !== null) {
      //@ts-ignore
      let UserId: string = user.id;
      // @ts-ignore
      dispatch(fullDeleteUser(obj));
      // logout();
    }
    // .then((result: any) => {
    //   // GOOGLE TAG
    //   window.dataLayer.push({
    //     total_workers: coworkers.length - 1,
    //     workshop_ID: workshopId,
    //     event: "add_edit_user",
    //   });
    // });
    // setModalMobile(false);
  };

  const passwordRender = () => {
    return (
      <Form
        form={passwordForm}
        initialValues={{
          newPassword: "",
          repNewPassword: "",
        }}
        onFinish={(data) => onPasswordChange(data)}
        onFinishFailed={(dataFailed) => passwordFailed(dataFailed)}
      >
        <div className="row-info">
          {passwordFlag && (
            <div className="col-info-right">
              <Form.Item
                hasFeedback
                label="Nueva contraseña"
                name="newPassword"
                help="Debe tener al menos ocho caracteres, minúsculas, mayúsculas, por lo menos un número y un símbolo."
                rules={[
                  {
                    required: true,
                    message: "Digite su contraseña.",
                  },
                  {
                    pattern: passwordRegex,
                    message:
                      "Debe tener al menos ocho caracteres, minúsculas, mayúsculas, por lo menos un número y un símbolo.",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Nueva contraseña"
                  onChange={(e) => passwordForm.resetFields(["repNewPassword"])}
                />
              </Form.Item>
              <Form.Item
                hasFeedback
                label="Confirmar nueva contraseña"
                name="repNewPassword"
                rules={[
                  {
                    required: true,
                    message: "Confirme su contraseña.",
                  },
                  {
                    pattern: passwordRegex,
                    message:
                      "Debe tener al menos ocho caracteres, minúsculas, mayúsculas, por lo menos un número y un símbolo.",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Las contraseñas que ingresaste no coinciden."
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirmar nueva contraseña" />
              </Form.Item>
            </div>
          )}
        </div>
        <div className="password-options">
          <CkButton
            style={{ width: "auto" }}
            hidden={passwordFlag}
            variant="link"
            color="primary"
            onClick={() => setPasswordFlag(true)}
          >
            Cambiar contraseña
          </CkButton>
          <CkButton
            style={{ width: "auto" }}
            hidden={!passwordFlag}
            variant="link"
            color="primary"
            htmlType="submit"
          >
            Guardar nueva contraseña
          </CkButton>
        </div>
      </Form>
    );
  };

  return (
    <div className="profile-container">
      <div className="photo-container">
        {!!photoUrl && sasToken ? (
          <Upload {...props} showUploadList={false}>
            <figure>
              <Image
                {...props}
                className="ws-photo"
                preview={false}
                src={
                  photoUrl && sasToken && photoUrl !== ""
                    ? `${photoUrl}?${sasToken}`
                    : avatarDefault
                }
              />
              <figcaption>
                <Button className="btn-photo">Cambiar foto</Button>
              </figcaption>
            </figure>
          </Upload>
        ) : (
          <CkEmptyImage {...props} showUploadList={false} />
        )}
      </div>
      <div className="profile-info">
        <Form
          form={profileForm}
          initialValues={{
            firstName: user ? user.name : "",
            lastName: user ? user.lastName : "",
            phone: user ? user.phone : undefined,
            date: user?.dateOfBirth
              ? moment(new Date(user.dateOfBirth), "DD/MM/YYYY")
              : "",
            //@ts-ignore
            gender: user ? user.sex : undefined,
            email: user ? user.email : "",
          }}
          onFinish={(data) => onFinish(data)}
          onFinishFailed={(dataFailed) => infoFailed(dataFailed)}
        >
          <div className="row-info">
            <div className="col-info-left">
              <CkInput
                label="Nombre(s)"
                name="firstName"
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Ingresa el nombre del usuario.",
                  },
                ]}
                inputProps={{
                  placeholder: "Nombre",
                }}
              />
              <CkInput
                label="Apellido(s)"
                name="lastName"
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Ingresa el apellido del usuario.",
                  },
                ]}
                inputProps={{
                  placeholder: "Apellido",
                }}
              />
            </div>
            <div className="col-info-right">
              <CkInput
                label="Teléfono celular"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el teléfono celular del usuario.",
                  },
                  {
                    pattern: new RegExp(/^[0-9]*$/gi),
                    message: "Sólo se permiten números.",
                  },
                  {
                    max: 10,
                    min: 10,
                    message: "El teléfono celular debe ser de 10 números.",
                  },
                ]}
                inputProps={{
                  placeholder: "Teléfono celular",
                  maxLength: 10,
                }}
              />
              <CkDatePicker
                label="Fecha de nacimiento"
                name="date"
                rules={[
                  {
                    required: true,
                    type: "date",
                    message: "Ingresa la fecha de nacimiento del usuario.",
                  },
                ]}
                inputProps={{
                  placeholder: "Fecha de nacimiento",
                  format: "DD/MM/YYYY",
                }}
              />
            </div>
            <div className="col-info-left">
              <Form.Item
                label="Soy"
                name="gender"
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Ingresa el genero del usuario.",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="M">Hombre</Radio>
                  <Radio value="F">Mujer</Radio>
                  <Radio value="N">Prefiero no contestar</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <Divider
            style={{ border: "border: 1px solid #CCCCCC", margin: "10px 0px" }}
            dashed
          />
          <div className="row-info">
            <div className="col-info-left">
              <CkInput
                label="Correo electrónico"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message:
                      "Ingresa un correo electrónico válido del usuario.",
                  },
                ]}
                inputProps={{
                  disabled: true,
                  placeholder: "Correo electrónico",
                }}
              />
            </div>
          </div>

          {passwordRender()}

          <Divider
            style={{ border: "border: 1px solid #CCCCCC", margin: "10px 0px" }}
            dashed
          />
          <div className="options-container">
            <div className="delete-data-btn-container">
              <CkButton
                style={{width: "auto"}}
                variant="link"
                color="danger"
                onClick={() =>
                  isOwner
                    ? setOwnerModal(true)
                    : setShowDeleteAccountConfirmation(true)
                }
              >
                Borrar datos
              </CkButton>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <CkIcon name="information-round" width="20px" height="20px" fill=""/>
                <p style={{margin: 0, paddingLeft: "5px", fontSize: "14px"}}>
                  Conoce más sobre <a target="_blank" className="data-retention-link-btn-txt" onClick={() => {
                  setIsDataRetentionModalOpen(true)
                }}>la retención de tus datos.</a>
                </p>
              </div>
            </div>

            <CkButton
              style={{width: "auto"}}
              variant="primary"
              color="primary"
              htmlType="submit"
            >
              Guardar datos
            </CkButton>
          </div>
        </Form>
      </div>

      <CkModal
        open={isDataRetentionModalOpen}
        onCancel={() => {
          setIsDataRetentionModalOpen(false)
        }}
      >
        <DataDeletionNotice title="Retención de datos"/>
      </CkModal>

      {isOwner ? (
        <CkModal
          className="delete-account-confirmation-modal"
          title={"¡Estas a punto de eliminar este taller!"}
          footer={
            <div className="help-container">
              <p>
                Si prefieres transferírselo a alguien más, contacta a Customer
                Service
              </p>
              <a href="tel:5522820800" target="_blank">
                <PhoneOutlined className="img-icon-doublespecial v-p" />
                55 2282 0800
              </a>
              <a href="https://wa.me/+525564359958" target="_blank">
                <WhatsappIcon className="img-icon-doublespecial v-w" />
                55 6435 9958
              </a>
            </div>
          }
          open={showDeleteAccountConfirmation}
          onCancel={() => setShowDeleteAccountConfirmation(false)}
          zIndex={3000}
          destroyOnClose
          afterClose={() => setDeleteText("")}
          type="delete"
          actionButtonsDirection="column"
          primaryAction={{
            disabled: deleteText !== "ELIMINAR",
            label: "Confirmar",
            onClick: () => {
              if (deleteText === "ELIMINAR") {
                setShowDeleteAccountConfirmation(false);
                setShowDeleteAccountMessage(true);
              }
            },
          }}
          secondaryAction={{
            label: "Cancelar",
            onClick: () => setShowDeleteAccountConfirmation(false),
          }}
        >
          <WarningOutlined className="img-icon-double" />
          <p>
            Esta acción no se podrá revertir y eliminará toda la información
            incluyendo la lista de talleres y mecánicos.
          </p>
          <p>
            Escribe la palabra <span>ELIMINAR</span> para confirmar.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CkInput
              variant="rounded"
              inputProps={{
                onChange: (e) => setDeleteText(e.target.value.toUpperCase()),
                onPressEnter: () => {
                  if (deleteText === "ELIMINAR") {
                    setShowDeleteAccountConfirmation(false);
                    setShowDeleteAccountMessage(true);
                  }
                },
              }}
            />
          </div>
        </CkModal>
      ) : (
        <ConfirmationModal
          visible={showDeleteAccountConfirmation}
          onOk={() => {
            setShowDeleteAccountConfirmation(false);
            setShowDeleteAccountMessage(true);
          }}
          onCancel={() => setShowDeleteAccountConfirmation(false)}
          title="Borrar cuenta"
          message="¿Estás seguro que quieres borrar este perfil?"
          icon={<DeleteProfileIcon />}
          type="delete"
        />
      )}
      <CkModal
        title={"Eliminar perfil"}
        open={showDeleteAccountMessage}
        onCancel={() => setShowDeleteAccountMessage(false)}
        maskClosable={false}
        destroyOnClose={true}
        className="delete-account-confirmation-modal"
        zIndex={3000}
        type="delete"
        actionButtonsDirection="column"
        primaryAction={{
          label: "Dar de baja",
          onClick: () => RemoveCoworker(confirmationModal.index),
        }}
      >
        <DeleteProfileIcon />
        <p>
          <span>Estimado {user?.name}</span>: Lamentamos mucho tu decisión de
          marcharte de Carker, sin embargo ante todo respetamos tu decisión. En
          línea con nuestro Aviso de Privacidad, te confirmamos que en el
          momento de dar click en el botón "Dar de Baja", tu información
          personal, la de tu taller y sus colaboradores será bloqueada dentro de
          nuestra base de datos, y dejarás de recibir notificaciones de nuestra
          parte.
        </p>
        <p>Te agradecemos si puedes indicarnos en qué fallamos:</p>
        <div className="reasons-container">
          {reasons.map((element, id) => {
            return (
              <Checkbox
                style={{ marginLeft: "8px" }}
                onChange={(e) =>
                  handleReasonCheckbox(element, e.target.checked)
                }
                // disabled={!agreement}
                className="--alter --orange"
              >
                {element}
              </Checkbox>
            );
          })}
          <Checkbox
            onClick={() => setEnableMessage(!enableMessage)}
            // disabled={!agreement}
            className="--alter --orange"
          >
            Si tu razón para marcharte es otra, o deseas darnos algún comentario
            para hacerlo mejor favor de compartirlo
          </Checkbox>
        </div>
        <Form>
          <Form.Item extra={<span>Mensaje</span>} className="align-extra-start">
            <TextArea
              className="--custom"
              disabled={!enableMessage}
              onChange={(e) => handleReasonMessage(e.target.value)}
              rows={5}
            />
          </Form.Item>
        </Form>
      </CkModal>
      {isOwner && (
        <CkModal
          zIndex={3000}
          className="delete-account-confirmation-modal"
          open={ownerModal}
          onCancel={() => setOwnerModal(false)}
          title="Lo sentimos"
        >
          <p>
            Este perfil no puede ser eliminado debido a que es dueño de un
            taller
          </p>
        </CkModal>
      )}
    </div>
  );
};

export default memo(MyProfile);
