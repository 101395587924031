import React, { useState, useEffect, useRef, memo, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Button,
  Input,
  Form,
  Pagination,
  PaginationProps,
  Spin,
  Alert,
  Divider,
  Skeleton,
  Progress,
  Popover,
  Empty,
} from "antd";
import moment from "moment";

import {
  getWorkshops,
  setCurrentSection,
  setRatings,
  getRatings,
} from "../../../stores";
import { FeedbackAPI, RatingAPI } from "../../../app/Services/axios";
import { CaretDownIcon } from "../../../assets/SvgIcons";
import {
  DownOutlined,
  UpOutlined,
  MenuOutlined,
  EnterOutlined,
  FlagOutlined,
  StarFilled,
  CloseOutlined,
} from "@ant-design/icons";
import { Workshop } from "shared/src/data-models/Workshop";
import FilterModal from "./RateFilters";
import { CkRate, CkButton, CkModal, CkMessage } from "../../../CkUI";
import "./styles.css";

export type dateType = "lastMonth" | "last3Months" | "last6Months" | "lastYear";
export type orderType = "DATEASC" | "DATEDSC" | "SCOREASC" | "SCOREDSC";

interface IMessage {
  id: number;
  isWorkshopMessage: boolean;
  message: string;
  createdDate: string;
  senderName: string;
  isVisible: boolean | null;
  isMessageReported: boolean;
}

interface RatingRange {
  lowerRating: number;
  upperRating: number;
  ratingCount: number;
}

interface RatingInfo {
  lowerRating: number;
  upperRating: number;
  ratingCount: number;
}

interface IProps {}

interface Tag {
  label: string;
  value: string;
}

const { TextArea } = Input;

const tagsList = [
  { label: "Puntualidad", value: "PUNCTU" },
  { label: "Amabilidad", value: "KINSTA" },
  { label: "Instalaciones", value: "FACILI" },
  { label: "Calidad del servicio", value: "QUASERVEH" },
  { label: "Relación calidad - precio", value: "QUAPRIRAT" },
  { label: "Entrega puntual y correcta del vehículo", value: "FINDELTIM" },
];

const orderList: { label: string; value: orderType }[] = [
  { label: "Más recientes", value: "DATEASC" },
  { label: "Más antiguos", value: "DATEDSC" },
  { label: "Más estrellas", value: "SCOREASC" },
  { label: "Menos estrellas", value: "SCOREDSC" },
];

const rateCountList = [5, 4, 3, 2, 1];

function calculatePercentage(count, RatingOverall) {
  // Sum all ratingCounts
  const totalSum = RatingOverall.reduce(
    (total, item) => total + item.ratingCount,
    0
  );

  // Calculate percentage
  const percentage = (count / totalSum) * 100;

  return percentage;
}

function sumRatingCounts(ratings: RatingInfo[]): number {
  let totalRatingCount: number = 0;
  for (const rating of ratings) {
    totalRatingCount += rating.ratingCount;
  }
  return totalRatingCount;
}

function findMissingTags(tagsList: any[], repornseList: any[]): Tag[] {
  const missingTags: Tag[] = [];

  for (const tag of tagsList) {
    const found = repornseList.some(
      (item) => item.endConsumerFeedbackCategoryCode === tag.value
    );
    if (!found) {
      missingTags.push(tag);
    }
  }

  return missingTags;
}

function getCustomDateRange(option) {
  const today = moment();
  let dateFrom, dateTo;

  switch (option) {
    case "lastMonth":
      dateFrom = moment(today).subtract(1, "months").startOf("month");
      dateTo = moment(today).subtract(1, "months").endOf("month");
      break;
    case "last3Months":
      dateFrom = moment(today).subtract(3, "months").startOf("month");
      dateTo = moment(today).endOf("month");
      break;
    case "last6Months":
      dateFrom = moment(today).subtract(6, "months").startOf("month");
      dateTo = moment(today).endOf("month");
      break;
    case "lastYear":
      dateFrom = moment(today).subtract(1, "year").startOf("year");
      dateTo = moment(today).endOf("year");
      break;
    default:
      dateFrom = moment(today).subtract(1, "months").startOf("month");
      dateTo = moment(today).subtract(1, "months").endOf("month");
  }

  return { dateFrom, dateTo };
}

const RateCountRender: FC<{ list: RatingRange[] }> = memo(({ list }) => {
  const listRev = list;

  const action = (index) => {
    return calculatePercentage(listRev[index].ratingCount, listRev);
  };

  return (
    <>
      {rateCountList.map((item, index) => (
        <div className="rate-count">
          <p className="num">{item}</p>
          <StarFilled />
          <Progress percent={action(index)} showInfo={false} />
          <p className="info">{listRev[index].ratingCount} calificaciones</p>
        </div>
      ))}
    </>
  );
});

const Ratings: FC<IProps> = () => {
  const { workshopId } = useParams<{ workshopId: string | undefined }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentPage = useRef<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [selectedWorkshop, setSelectedWorkshop] = useState<any>(undefined);
  const [selectedWorkshopRatingDetails, setSelectedWorkshopRatingDetails] =
    useState<any[]>([]);
  const [feedbackNum, setFeedbackNum] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const workshops: Workshop[] = useSelector(getWorkshops);

  const [tags, setTags] = useState<any[] | undefined>(undefined);
  const [ratingData, setRatingData] = useState<any | undefined>(undefined);
  const [rateCounts, setRateCounts] = useState<RatingRange[] | undefined>(
    undefined
  );

  const [loadingTags, setLoadingTags] = useState<boolean>(true);

  // Report States
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [succesReportModal, setSuccesReportModal] = useState<boolean>(false);
  const [IdList, setIdList] = useState<number[] | undefined>(undefined);
  const [idWillReport, setIdWillReport] = useState<string | undefined>(
    undefined
  );
  const [reportReason, setReportReason] = useState<string | undefined>(
    undefined
  );
  const [reportedItems, setReportedItems] = useState<string[]>([]);
  const [categoryReport, setCategoryReport] = useState<any[] | undefined>(
    undefined
  );

  // Filters
  const [filtersModal, setFiltersModal] = useState<boolean>(false);
  const [orderSelected, setOrderSelected] = useState<orderType>("DATEASC");
  const [dateRange, setDateRange] = useState<dateType | undefined>(undefined);
  const [rateRange, setRateRange] = useState<
    | {
        rankingFrom: number;
        rankingTo: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    console.log("filtersModal: ", filtersModal);
  }, [filtersModal]);

  useEffect(() => {
    dispatch(setCurrentSection("Calificaciones"));
  }, []);

  useEffect(() => {
    if (workshops.length === 0 || workshopId === undefined) {
      setSelectedWorkshop(undefined);
      return;
    }
    const workshop = workshops.filter(
      (current) => current.id === workshopId
    )[0];
    if (workshop === undefined) {
      CkMessage({
        type: "error",
        text: "No se pudo obtener las calificaciones del taller seleccionado."
      });

      history.push("/citas");
      return;
    }
    setSelectedWorkshop(workshop);
  }, [workshops, workshopId]);

  useEffect(() => {
    goTop();
    selectedWorkshop && getAllFeedbacks();
  }, [selectedWorkshop, pageSize, page]);

  useEffect(() => {
    if (!initialLoading) {
      setLoading(true);
      getAllFeedbacks(true);
    }
  }, [dateRange, rateRange, orderSelected]);

  useEffect(() => {
    selectedWorkshop && getRatingOverall();
    selectedWorkshop && getTags();
  }, [selectedWorkshop]);

  useEffect(() => {
    getCategoryReports();
  }, []);

  const getCategoryReports = async (reset?: boolean) => {
    RatingAPI.getCategoryReports()
      .then((response) => {
        if (response && response.data) {
          setCategoryReport(response.data);
        }
      })
      .catch((err) => {
        CkMessage({
          type: "error",
          text: "Ocurrió un error, no es posible realizar reportes"
        });
      });
  };

  const putReport = () => {
    setLoadingReport(true);
    IdList.map((idItem) => {
      RatingAPI.putReport(idItem, reportReason)
        .then((response) => {
          if (response && response.data == true) {
            let tempReported = [...reportedItems];
            tempReported.push(idWillReport);
            cleanReportModal();
            setSuccesReportModal(true);
            setReportedItems([...tempReported]);
          } else {
            setLoadingReport(false);
          }
        })
        .catch((err) => {
          CkMessage({ type: "error", text: "Ocurrió un error, intenta más tarde"});
          setLoadingReport(false);
        });
    });
  };

  const textWithNewLines = (text: string, className: string) => {
    if (text) {
      const split = text.trim().split("\n");
      return split.map((line, index) => (
        <p className={className}>
          {/* {index === 0 && '"'} */}
          {line}
          {/* {index === split.length - 1 && '"'} */}
        </p>
      ));
    } else {
      return <></>;
    }
  };

  const getRatingOverall = () => {
    const payloadOverall = [
      {
        lowerRating: 0,
        upperRating: 1.2,
      },
      {
        lowerRating: 1.3,
        upperRating: 2.2,
      },
      {
        lowerRating: 2.3,
        upperRating: 3.2,
      },
      {
        lowerRating: 3.3,
        upperRating: 4.2,
      },
      {
        lowerRating: 4.3,
        upperRating: 5,
      },
    ];

    FeedbackAPI.getRatingOverall(selectedWorkshop.id, payloadOverall)
      .then((response) => {
        if (response && response.data) {
          setRateCounts([...response.data.reverse()]);
        }
      })
      .catch((err) => {
        CkMessage({
          type: "error",
          text: "Ocurrió un problema al obtener las estadisticas"
        });
      });
  };

  const getAllFeedbacks = (reload?) => {
    const datePayload = getCustomDateRange(dateRange);
    const ratePayload = { rankingFrom: 0, rankingTo: 5 };

    if (rateRange) {
      ratePayload.rankingTo = rateRange.rankingTo;
      ratePayload.rankingFrom = rateRange.rankingFrom;
    }

    const payload = {
      workshopId: selectedWorkshop.id,
      page: reload ? 1 : page,
      pageSize: pageSize,
      dateFrom: undefined,
      dateTo: undefined,
      rankingFrom: ratePayload.rankingFrom,
      rankingTo: ratePayload.rankingTo,
      sortCriteria: orderSelected,
    };

    if (dateRange) {
      payload.dateFrom = moment(datePayload.dateFrom).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      payload.dateTo = moment(datePayload.dateTo).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
    }

    FeedbackAPI.getWorkshopRating(
      payload.workshopId,
      payload.page,
      payload.pageSize,
      payload.dateFrom,
      payload.dateTo,
      payload.rankingFrom,
      payload.rankingTo,
      payload.sortCriteria
    )
      .then((response) => {
        const { data } = response;

        // Set navigation helper
        if (page === 1) {
          dispatch(
            setRatings({
              workshopName: selectedWorkshop.name,
              total: data.feedbackScore,
              averages: data.ratings,
            })
          );
        }

        setRatingData(data);
        setSelectedWorkshopRatingDetails(data.ratingDetails);

        data.numberOfFeedbacks !== feedbackNum &&
          setFeedbackNum(data.numberOfFeedbacks);
      })
      .catch(() => {
        CkMessage({
          type: "error",
          text: "No se pudo obtener las calificaciones del taller seleccionado."
        });

        currentPage.current === 1 && history.push("/citas");
      })
      .finally(() => {
        if (reload) {
          setPage(1);
        }
        setLoading(false);
        setInitialLoading(false);
      });
  };

  const getTags = async (reset?: boolean) => {
    RatingAPI.getWorkshopTags(workshopId)
      .then((response) => {
        //@ts-ignore
        const { data }: { data: any } = response;

        if (data && data.length > 0) {
          setTags([...data]);
        }
      })
      .catch((err) => {
        console.error("getWorkshopTags: ", err);
      })
      .finally(() => {
        setLoadingTags(false);
      });
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (_, pageSize) =>
    setPageSize(pageSize);

  const onChange: PaginationProps["onChange"] = (pageNumber) =>
    setPage(pageNumber);

  const applyFilters = (
    date: dateType | undefined,
    range:
      | {
          rankingFrom: number;
          rankingTo: number;
        }
      | undefined
  ) => {
    setDateRange(date);
    setRateRange(range);
    setFiltersModal(false);
  };

  const cleanFilters = () => {
    setDateRange(undefined);
    setRateRange(undefined);
    setFiltersModal(false);
  };

  const closeFilters = () => {
    setFiltersModal(false);
  };

  const goTop = () =>
    document.querySelector(".rate-card-container") &&
    document
      .querySelector(".rate-card-container")!
      .scrollIntoView({ behavior: "smooth" });

  const cleanReportModal = () => {
    setIdWillReport(undefined);
    setReportReason(undefined);
    setLoadingReport(false);
    setShowReport(false);
  };

  const missingTagsRender = (list: Tag[] | undefined) => {
    return list && list.length > 0 ? (
      <>
        <Alert
          className="info-gray"
          description="Abajo te mostramos los atributos adicionales que aún no están asociados a tu taller. Te sugerimos considerar si puedes realizar mejoras en estas áreas. Así, podrás ofrecer un servicio cada vez mejor con aspectos que los clientes valoran."
          type="info"
          showIcon
        />
        <div className="row-item flex-wrap" style={{ marginBottom: "10px" }}>
          {list.map((item) => (
            <p key={item.value} className="tag-text-gray s12">
              {item.label}
            </p>
          ))}
        </div>
      </>
    ) : null;
  };

  const openModal = (item: any) => {
    if (item.messages && item.messages.length > 0) {
      let ids: number[] = [];
      item.messages.map((e) =>
        e.isWorkshopMessage == false ? ids.push(e.id) : null
      );
      setIdList([...ids]);
    }

    setIdWillReport(item.workshopAppointmentId);
    setShowReport(true);
  };

  const OrderContent = (
    <div className="order-content">
      {orderList.map((item) => (
        <CkButton
          block
          variant={item.value == orderSelected ? "primary" : "text"}
          color="primary"
          onClick={() => setOrderSelected(item.value)}
        >
          {item.label}
        </CkButton>
      ))}
    </div>
  );

  const RateCard: FC<{
    rate: any;
    setShowReport?: Function;
    setIdWillReport?: Function;
  }> = ({ rate }) => {
    const [form] = Form.useForm();
    const [userMessage, setUserMessage] = useState<IMessage>();
    const [workshopMessage, setWorkshopMessage] = useState<IMessage>();
    const [wokrshopResponse, setWokrshopResponse] = useState<string>();
    const [showThread, setShowThread] = useState<boolean>(false);
    const [showSubmit, setShowSubmit] = useState<boolean>(false);

    useEffect(() => {
      const messagesList = rate.messages.filter(
        (res) => res.isMessageReported !== true
      );

      if (!!rate === false) return;
      if (messagesList && messagesList[0] !== undefined)
        setUserMessage(messagesList[0]);
      if (messagesList && messagesList[1] !== undefined)
        setWorkshopMessage(messagesList[1]);
    }, rate);

    const isLocaleReported = () => {
      return (
        reportedItems.filter((item) => item == rate.workshopAppointmentId)
          .length > 0 || false
      );
    };

    const submit = () => {
      if (
        userMessage === undefined ||
        wokrshopResponse === undefined ||
        wokrshopResponse.length < 3
      )
        return;
      FeedbackAPI.addMessage(userMessage.id.toString(), wokrshopResponse)
        .then((response) => {
          const { data } = response;
          data &&
            setWorkshopMessage({
              id: 0,
              message: wokrshopResponse,
              createdDate: moment().toISOString(),
              isWorkshopMessage: true,
              senderName: "",
              isVisible: false,
              isMessageReported: false,
            });
          setShowSubmit(false);
          CkMessage({ type: "error", text: "Respuesta enviada correctamente"});
        })
        .catch((err) => {
          CkMessage({ type: "error", text: "Ocurrió un error al enviar la respuesta"});
        });
    };

    const commentRender = (isReported: boolean) => {
      return (
        <div
          key={rate?.workshopAppointmentId}
          className={`rate-card ${isReported ? "reported" : ""}`}
        >
          <div className="row-item flex-wrap space-between">
            <p className="text-bold primary-color">
              {rate.endConsumer.name || ""} {rate.endConsumer.lastName || ""}
            </p>
            <p className="text-regular primary-color">
              {moment(rate?.createdDate).format("DD/MMMM/YYYY")}
            </p>
          </div>

          <div className="col-item">
            <p className="text-regular">
              Servicio realizado:{" "}
              {[
                ...rate.packages.map((pack: any) => pack.servicePackageDesc),
                ...rate.services.map((service: any) => service.serviceName),
              ].join(", ")}{" "}
            </p>
          </div>

          <div className="col-item">
            <CkRate
              disabled
              allowHalf
              defaultValue={rate?.endConsumerFeedbackScore}
              style={{ color: "var(--secondary-dark-color)" }}
            />
          </div>

          {rate && rate.ratings && rate.ratings.length > 0 && (
            <div
              className="row-item flex-wrap"
              style={{ paddingBottom: "10px" }}
            >
              {rate.ratings.map((item) => (
                <p key={item.feedbackCategoryCode} className="tag-text s12">
                  {item.feedbackCategoryName}
                </p>
              ))}
            </div>
          )}

          {!isReported && userMessage && (
            <div className="comment-container">
              {!!userMessage.isVisible === false && (
                <p className="notice">
                  El mensaje del cliente está siendo revisado por Servicio al
                  cliente antes de publicarse
                </p>
              )}
              {!!userMessage.isVisible &&
                userMessage.message &&
                userMessage.message.trim().split("\n").length > 0 && (
                  <p className="message-text">
                    {textWithNewLines(userMessage.message, "comment")}
                  </p>
                )}
            </div>
          )}

          {(workshopMessage !== undefined ||
            (userMessage &&
              userMessage.isVisible &&
              workshopMessage === undefined)) &&
            !isReported && (
              <div
                className={["actions", ...(showSubmit ? ["hide"] : [])].join(
                  " "
                )}
              >
                <CkButton
                  style={{ width: "auto" }}
                  variant="link"
                  color="primary"
                  // className="expand-submit"
                  icon={<FlagOutlined />}
                  onClick={() => openModal(rate)}
                  // disabled
                >
                  Reportar
                </CkButton>

                {workshopMessage !== undefined && (
                  <CkButton
                    style={{ width: "auto" }}
                    onClick={() => {
                      setShowThread(!showThread);
                      showSubmit && setShowSubmit(false);
                    }}
                    className={["", ...(showThread ? ["expanded"] : [])].join(
                      " "
                    )}
                    variant="text"
                    color="primary"
                    icon={<CaretDownIcon />}
                    ghost
                  >
                    Ver conversación
                  </CkButton>
                )}

                {userMessage &&
                  userMessage.isVisible &&
                  workshopMessage === undefined && (
                    <CkButton
                      style={{ width: "auto" }}
                      onClick={() => {
                        setShowSubmit(!showSubmit);
                        showThread && setShowThread(false);
                      }}
                      // icon={<EnterOutlined className="transform-180" />}
                      type="link"
                      block={false}
                    >
                      Responder
                    </CkButton>
                  )}
              </div>
            )}

          {workshopMessage && (
            <div
              className={[
                "conversation-thread",
                ...(showThread ? ["expanded"] : []),
              ].join(" ")}
            >
              <div className="thread-comment">
                <p className="comment-date">
                  El{" "}
                  {moment(workshopMessage.createdDate).format("DD/MMMM/YYYY")},
                  tu respondiste:
                </p>
                {textWithNewLines(workshopMessage.message, "comment")}
                {!!workshopMessage.isVisible === false && (
                  <p className="notice">
                    Su mensaje está siendo revisado por Servicio al cliente
                    antes de publicarse
                  </p>
                )}
              </div>
            </div>
          )}

          {userMessage &&
            userMessage.isVisible &&
            workshopMessage === undefined && (
              <div
                className={[
                  "submit-message",
                  ...(showSubmit ? ["expanded"] : []),
                ].join(" ")}
              >
                <Form form={form} layout="vertical">
                  <Form.Item
                    name="message"
                    rules={[{ required: true, message: "Ingrese un mensaje" }]}
                    help={"El mensaje puede tener un máximo de 300 caracteres."}
                    label={<span>Escribe tu respuesta</span>}
                    colon={false}
                  >
                    <TextArea
                      rows={4}
                      maxLength={300}
                      value={wokrshopResponse}
                      onChange={(e) =>
                        setWokrshopResponse(e.currentTarget.value)
                      }
                    />
                  </Form.Item>
                </Form>
                <div className="submit-options">
                  <CkButton
                    variant={"link"}
                    block={false}
                    onClick={() => {
                      form.resetFields();
                      setWokrshopResponse(undefined);
                      setShowSubmit(false);
                    }}
                  >
                    Cancelar
                  </CkButton>
                  <CkButton
                    disabled={
                      wokrshopResponse === undefined ||
                      wokrshopResponse?.trim().length < 3
                    }
                    onClick={submit}
                  >
                    Enviar respuesta
                  </CkButton>
                </div>
              </div>
            )}
        </div>
      );
    };

    return commentRender(isLocaleReported());
  };

  return (
    <section className="ratings-by-workshop">
      {!ratingData && (
        <div
          className="rate-container"
          style={{ gap: "5px", marginBottom: "20px" }}
        >
          <Skeleton.Input size="small" active />
          <Skeleton.Button active />
          <Skeleton.Input size="small" active />
        </div>
      )}
      <div className="flex-rate-container">
        {ratingData && (
          <div className="rate-container">
            <p className="text-bold primary-color">
              {ratingData.workshop.name || ""}
              {` - ${ratingData.workshop.sucursalName}` || ""}
            </p>
            <p className="text-regular primary-color no-margin">Calificación</p>
            <p className="text-bold no-margin">{`${ratingData.feedbackScore} de 5`}</p>
            <CkRate allowHalf value={ratingData.feedbackScore} disabled />
          </div>
        )}

        {rateCounts && ratingData && (
          <div className="rate-count-container">
            <p className="text-regular primary-color">
              {sumRatingCounts(rateCounts)} calificaciones
            </p>
            <RateCountRender list={rateCounts} />
          </div>
        )}
      </div>

      {!tags && <Skeleton active />}
      {tags && (
        <div className="tags-container">
          <p className="bold-primary" style={{ marginBottom: 5 }}>
            De acuerdo a tus clientes, el taller destaca por su:
          </p>
          {tags.map((item) => (
            <div className="row-item">
              <p
                key={item.endConsumerFeedbackCategoryCode}
                className="tag-text s12"
              >
                {item.endConsumerFeedbackCategoryName}
              </p>
              <p className="s12">
                {item.endConsumerFeedbackCategoryWorkshopCount} clientes
              </p>
            </div>
          ))}

          {tags && missingTagsRender(findMissingTags(tagsList, tags))}
        </div>
      )}

      <Divider
        plain
        style={{ marginTop: "10px", borderColor: "var(--primary-color)" }}
      />

      <div>
        <p className="text-bold primary-color">Detalle de calificaciones</p>
        {ratingData && (
          <div
            className="row-item flex-wrap space-between"
            style={{ marginBottom: "10px" }}
          >
            <p className="tag-text-gray s12">
              {ratingData.numberOfFeedbacks} calificaciones
            </p>
            <div className="row-item">
              <CkButton
                className="text-regular primary-color btn-sort"
                variant="secondary"
                color="primary"
                icon={
                  <div className="menu-icons">
                    <MenuOutlined />
                  </div>
                }
                onClick={() => setFiltersModal(true)}
              >
                Filtros
              </CkButton>
              <Popover
                overlayStyle={{ borderRadius: "10px" }}
                overlayInnerStyle={{ borderRadius: "10px" }}
                placement="bottomRight"
                title={null}
                content={OrderContent}
              >
                <CkButton
                  className="text-regular primary-color btn-sort"
                  variant="secondary"
                  color="primary"
                  icon={
                    <div className="order-icons">
                      <UpOutlined style={{ marginTop: "-5px" }} />
                      <DownOutlined />
                    </div>
                  }
                >
                  Orden
                </CkButton>
              </Popover>
            </div>
          </div>
        )}
      </div>

      <div className="rate-card-container">
        {selectedWorkshopRatingDetails &&
          selectedWorkshopRatingDetails.map((rate, key) => (
            <RateCard
              rate={rate}
              setShowReport={setShowReport}
              setIdWillReport={setIdWillReport}
              key={key}
            />
          ))}
      </div>

      {!selectedWorkshopRatingDetails ||
        (selectedWorkshopRatingDetails.length == 0 && <Empty />)}

      <Spin spinning={loading} />

      {ratingData && ratingData.pages > 0 && feedbackNum > 0 && (
        <Pagination
          showSizeChanger
          pageSizeOptions={["5", "10", "20", "50", "100"]}
          showTotal={(total, range) => `${range[0]}-${range[1]} de ${total}`}
          current={page}
          total={ratingData.pages * 10 || 0}
          onShowSizeChange={onShowSizeChange}
          onChange={onChange}
          disabled={loading}
        />
      )}

      {categoryReport && showReport && (
        <CkModal
          title="Reportar comentario"
          type="delete"
          open={showReport}
          onCancel={() => cleanReportModal()}
          primaryAction={{
            label: "Reportar",
            onClick: () => putReport(),
            disabled: !reportReason,
            loading: loadingReport,
          }}
          secondaryAction={{
            label: "Cancelar",
            onClick: () => cleanReportModal(),
            disabled: loadingReport,
          }}
        >
          <div className="report-modal-container">
            <p className="info">
              Selecciona la razón por la que estás reportando este comentario.
              Al reportar el comentario, será revisado por nuestro equipo de
              Servicio al Cliente.
            </p>

            {categoryReport.map((item) => (
              <CkButton
                style={{ width: "100%" }}
                disabled={
                  loadingReport || item.reportReasonCode == reportReason
                }
                className={
                  item.reportReasonCode == reportReason ? "active" : ""
                }
                variant="secondary"
                color="mariner"
                onClick={() => setReportReason(item.reportReasonCode)}
              >
                {item.reportReasonDesc}
              </CkButton>
            ))}
          </div>
        </CkModal>
      )}

      {succesReportModal && (
        <CkModal
          title="Reporte enviado"
          open={succesReportModal}
          style={{ maxWidth: "400px" }}
          bodyStyle={{ padding: "30px 20px" }}
          onCancel={() => setSuccesReportModal(false)}
        >
          <p style={{ width: "100%", textAlign: "center" }}>
            Gracias. Recibimos tu informe. Si determinamos que el contenido es
            inapropiado, lo quitaremos
          </p>
        </CkModal>
      )}

      {filtersModal && (
        <FilterModal
          open={filtersModal}
          initialDate={dateRange}
          initialRate={rateRange}
          closeModal={closeFilters}
          action={applyFilters}
          reset={cleanFilters}
        />
      )}
    </section>
  );
};

export default memo(Ratings);
